import { animation, style, animate, trigger, transition, useAnimation, state } from '@angular/animations';

export const slideUpDownAnimation = trigger('slideUpDown', [
	state('up', style({ height: '0', opacity: '0', overflow: 'hidden' })),
	state('down', style({ height: '*', opacity: '1', overflow: 'hidden' })),
	transition('up <=> down', animate('200ms ease-in-out'))]);

export const fadeInOutYAnimation = trigger('fadeInOutY', [
	state('out', style({ transform: 'translateY(-0.5rem)', opacity: '0' })),
	state('in', style({ transform: 'translateY(0)', opacity: '1' })),
	transition('out <=> in', animate('200ms ease-out'))]);
